import React from "react";
import "./componentLoader.scss";

export default function ComponentLoader({ type }) {
  return (
    <div
      className={
        type === "button"
          ? "componentLoadAnimation button"
          : "componentLoadAnimation"
      }
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
