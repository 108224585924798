import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/main.scss";
import Door from "./pages/overrideDoors/Door";

/* Import main components */
const Admin = React.lazy(() => import("./pages/Admin"));
const Contracts = React.lazy(() => import("./pages/Contracts"));
const Contract = React.lazy(() => import("./pages/contracts/Contract"));
const Payments = React.lazy(() => import("./pages/Payments"));
const AccessControl = React.lazy(() => import("./pages/AccessControl"));
const ParkingAllocation = React.lazy(() => import("./pages/ParkingAllocation"));
const SystemSettings = React.lazy(() => import("./pages/SystemSettings"));
const Persons = React.lazy(() => import("./pages/Persons"));
const Person = React.lazy(() => import("./pages/persons/Person"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Cafelogs = React.lazy(() => import("./pages/cafelog"));
const ContractBalanceDetails = React.lazy(() =>
  import("./pages/contracts/ContractBalanceDetails")
);
const Unit4 = React.lazy(() => import("./pages/unit4"));
const DoorStatus = React.lazy(() => import("./pages/doorStatus"));
const HotelCards = React.lazy(() => import("./pages/hotel-cards/HotelCards"));
const MainGateLog = React.lazy(() => import("./pages/main-gate-log/mainGateLog"));
/* End of main component import */

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <NotFound />
              </React.Suspense>
            }
          />
          <Route
            path="/"
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <Admin />
              </React.Suspense>
            }
          >
            <Route
              index={true}
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Dashboard />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="contracts"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Contracts />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="/contracts/:id"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Contract />
                </React.Suspense>
              }
            >
              <Route
                path="/contracts/:id/contractBalance/:balanceId"
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <ContractBalanceDetails />
                  </React.Suspense>
                }
              ></Route>
            </Route>

            <Route
              path="payments"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Payments />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="access_control"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <AccessControl />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="parking_allocation"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <ParkingAllocation />
                </React.Suspense>
              }
            ></Route>
              <Route
                  path="system_settings"
                  element={
                      <React.Suspense fallback={<div>Loading...</div>}>
                          <SystemSettings />
                      </React.Suspense>
                  }
              ></Route>
            <Route
              path="persons"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Persons />
                </React.Suspense>
              }
            ></Route>
            <Route
              path="person/:personKey"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Person />
                </React.Suspense>
              }
            ></Route>
            <Route path="cafelogs"
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <Cafelogs />
              </React.Suspense>
            }></Route>

            <Route
              path="unit4"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Unit4 />
                </React.Suspense>
              }
            ></Route>
            
            <Route
              path="doorstatus"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <DoorStatus />
                </React.Suspense>
              }
            ></Route>

            <Route
              path="main-gate-log"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <MainGateLog />
                </React.Suspense>
              }
            ></Route>

            <Route
              path="hotel-cards"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <HotelCards />
                </React.Suspense>
              }
            ></Route>

          </Route>        
        </Routes>
      </BrowserRouter>
    </>
  );
}
