import React from "react";
import "./door.scss";
import lock from "./lock.svg";
import { ComponentLoader } from "../../toolbox/loading";

export default function Door({ data, action, language, loading }) {
  let displayedBuilding = data?.buildingName ?? "";
  if (displayedBuilding?.length > 15) {
    displayedBuilding = displayedBuilding.toString().substring(0, 15) + "...";
  }

  let displayedRoom = data?.doorName ?? "";
  if (displayedRoom?.length > 15) {
    displayedRoom = displayedRoom.toString().substring(0, 15) + "...";
  }

  let hoverTitle = `Trykk for å åpne dør: ${displayedBuilding} ${data?.doorName}`;

  return (
    <div
      title={hoverTitle}
      className="overrideDoor"
      key={data?.doorKey}
      onClick={() => {
        action(data.doorKey);
      }}
    >
      <img src={lock} alt="Lock - press to open the door" />
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          <span style={{fontWeight: '600'}}>{displayedBuilding}</span>
          <span>{displayedRoom}</span>
        </>
      )}
    </div>
  );
}
