import React from "react";
import "./fullpageLoader.scss";

export default function FullpageLoader() {
  return (
    <>
      <div className="backdrop"></div>
      <div className="loading">
        <div className="dott"></div>
        <div className="dott"></div>
        <div className="dott"></div>
      </div>
    </>
  );
}
